<template>
	<div id="userlogin" class="boxsty login">
		<div class="titlestyle">快速创建已认证小程序</div>
		
		<div class="inputbox inputpad">
			<div class="label">中国 +86</div>
			<el-input class="input inputlabel" type="text" v-model="phone" placeholder="请输入手机号" />
		</div>

		<div class="inputbox" v-if="logintype == 1">
			<el-input class="input" type="password" v-model="password" placeholder="输入登陆密码" @keyup.enter.native="login" show-password />
		</div>

		<div class="inputbox" v-if="logintype == 2">
			<el-input class="input" type="text" v-model="code" placeholder="输入验证码" @keyup.enter.native="login" />
			<div class="codebox" v-if="!codeshow" @click="getcode">获取验证码</div>
			<div class="codebox" v-else style="color:#3773da;">{{ codetime }}秒后获取</div>
		</div>
		<div class="title">
			<div class="text" v-if="logintype==2" @click="logintype=1">使用密码登陆</div>
			<div class="text" v-if="logintype==1" @click="logintype=2">使用验证码登陆</div>
		</div>
		
		<div class="loginbtn" @click="login">登陆</div>
		<div class="logininfo">
			<div class="chekbox">
				<el-checkbox v-model="checked"></el-checkbox>
				我已阅读并同意
				<router-link to="/agreement" target="_blank">《用户使用协议》</router-link>
			</div>

			<div class="changbox">
				<div class="text" @click="forgetpwd">忘记密码</div>
				<div class="text" @click="toreg">免费注册</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util';
import {resetRouter} from '@/router/index.js'
export default {
	name: 'login',
	data() {
		return {
			phone: '',
			password: '',
			code: '',
			logintype: 1,
			codeshow: false,
			codetime: 59,
			checked: true
		};
	},
	methods: {
		//忘记密码
		forgetpwd() {
			this.$store.commit('changeApplyLogin', 2);
		},
		//注册
		toreg() {
			this.$store.commit('changeApplyLogin', 3);
		},
		handlogin(e) {
			this.login();
		},
		//登录
		login() {
			if (this.checked) {
				let data = {
					phone: this.phone,
					type: this.logintype,
				};

				if (this.logintype == 1) {
					data.password = this.password;
				} else {
					data.sms_code = this.code;
				}

				this.axios.post('/api/fast_register/login', data).then(res=>{
					if (res.code == 200) {
						sessionStorage.setItem('token', res.data);
						sessionStorage.setItem('guardName', 'fast_register_admin_user');
						this.$store.commit('changeIsApply', 1);
						sessionStorage.setItem('isApply',true)
						sessionStorage.removeItem('role_id')
						sessionStorage.removeItem('pluginroute')
						sessionStorage.removeItem('pluginid')
						this.getmeuns()
					}else {
						eltips(res.msg, 'error');
					}
				})
			} else {
				eltips('请阅读用户使用协议', 'warning');
			}
		},
		//获取菜单
		getmeuns() {
			resetRouter()
			this.$store.commit('routestatus', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			let wechatRoutes = [
				{
					component: "registerWechatMp/wechatApply.vue",
					icon: "cjxcx",
					layout: '1',
					name: "创建",
					path: "/apply/wechat",
					router_name: "wechatApply",
					vue_name: "wechatApply",
					app_level:0,
				},
				{
					component: "registerWechatMp/wechatApply.vue",
					icon: "dingdan",
					layout: '1',
					name: "订单",
					path: "/apply/order",
					router_name: "wechatApplyOrder",
					vue_name: "wechatApplyOrder",
					app_level:0,
				},
			]
			
			sessionStorage.setItem('routes',JSON.stringify(wechatRoutes))
			this.$router.replace({ path: '/apply/wechat' });
			eltips('登录成功', 'success');
		},

		//获取验证码
		getcode() {
			let that = this;
			this.axios.post('/api/send_code', { phone: this.phone }).then(res => {
				if (res.code == 200) {
					eltips(res.msg, 'success');
					that.codeshow = true;
					let autocode = setInterval(() => {
						if (that.codetime > 1) {
							that.codetime--;
						} else {
							that.codeshow = false;
							that.codetime = 59;
							clearInterval(autocode);
						}
					}, 1000);
				} else {
					eltips(res.msg, 'error');
				}
			});
		}
	}
};
</script>

<style>
#userlogin .el-input__inner {
	border: none !important;
	border-bottom: 1px solid #dcdee0 !important;
	height: 48px !important;
	line-height: 48px !important;
	padding: 0 !important;
	border-radius: 0 !important;
}
#userlogin .el-input__inner:focus {
	border-color: #3773da !important;
}
</style>
<style lang="less" scoped>
.login {
	.titlestyle{
		margin-right: 40px;
		font-size: 20px;
		color: #969799;
		font-size: 26px;
		color: #323233;
	}
	.title {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 30px;
		.text {
			display: inline-block;
			vertical-align: bottom;
			font-size: 14px;
			color: #969799;
			cursor: pointer;
		}
		.active {
			font-size: 16px;
			color: #323233;
		}
	}
	.inputpad {
		padding-top:10px;
	}
	.inputbox {
		margin-top: 30px;
		position: relative;
		.label {
			font-size: 16px;
			line-height: 47px;
			border-bottom: 1px solid #dcdee0;
			display: inline-block;
		}
		.input {
			font-size: 16px;
			width: 100%;
			height: 48px !important;
			line-height: 48px !important;
			box-sizing: border-box;
		}
		.codebox {
			line-height: 48px;
			position: absolute;
			right: 0;
			top: 0;
			font-size: 16px;
			color: #999;
		}
		.inputlabel {
			display: inline-block;
			width: 300px;
			float: right;
			flex: 0 0 300px;
		}
		.input:focus {
			border-color: #3773da;
		}
	}
	.loginbtn {
		margin-top: 58px;
		line-height: 48px;
		text-align: center;
		font-size: 18px;
		color: #fff;
		background: #155bd4;
		border-radius: 5px;
		&:hover {
			background: #3773da;
			cursor: pointer;
		}
	}
	.logininfo {
		overflow: hidden;
		margin-top: 24px;
		font-size: 14px;
		line-height: 14px;
		.chekbox {
			float: left;
			span {
				color: #155bd4;
			}
		}
		.changbox {
			float: right;
			font-size: 14px;
			.text {
				color: #155bd4;
				padding-right: 10px;
				line-height: 14px;
				display: inline-block;
				&:last-child {
					position: relative;
					padding-right: 0;
					padding-left: 10px;
				}
				&:last-child::after {
					content: '';
					width: 1px;
					height: 14px;
					position: absolute;
					left: 0;
					top: 0;
					background: #ebedf0;
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
</style>
